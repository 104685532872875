import React from 'react'
import Footer from '../components/Footer/Footer'
import Navbar from "../components/Navbar/Navbar"
import UslugiPages from "../components/Uslugi/UslugiPages"
import {BoxTextPages, BigTitlePages,  Flex,FlexContent, TitleServices, ListServices} from '../components/Uslugi/UslugiStyled'
import { StaticImage }from "gatsby-plugin-image"

import { theme } from "../components/theme.js";
import { ThemeProvider } from "styled-components"


import SEO from "../components/seo"

const profilaktyka = () => {
  return (

    <>
    <ThemeProvider theme={theme}>
    <SEO 
   title="Profilaktyka"
   description="Oferujemy szeroki wachlarz usług weterynaryjnych obejmujący leczenie chorób psów, kotów, zwierząt futerkowych i gryzoni. Oferujemy również podstawowe szczepienia psów, kotów, królików, tchórzofretek
    Jeżeli szukasz specjalisty lekarza weterynarii w Kaliszu - zapraszamy do Lecznicy dla Zwierząt znajdującej się w Kaliszu przy ul. Lipowej 17-21." 
    keywords="szczepienie psów kalisz, szczepienie wścieklizna kalisz"
    />
    <Navbar />

    <BigTitlePages style={{justifyContent: 'center'}}
         whileInView={{y: 0, opacity:1}}
         initial={{ y:50, opacity:0}}
         transition={{delay:0.25, duration: 0.75}}
         >Profilaktyka
    </BigTitlePages>  
    <FlexContent>
    <UslugiPages />

 <Flex>

<StaticImage
    src="../images/profilaktyka.jpeg"
    alt="Weterynarz Kalisz profilaktyka"
    width={1500}
    placeholder="blurred"
    className='staticimagepages'
/>

<BoxTextPages>

<TitleServices>Szczepienia podstawowe psów</TitleServices>
<ListServices> Wścieklizna</ListServices>
<ListServices> Nosówka</ListServices>
<ListServices> Parwowiroza</ListServices>
<ListServices> Choroba Rubartha</ListServices>
<ListServices> Leptospiroza</ListServices>
<ListServices> Parainfluenza</ListServices>
<ListServices> Herpeswiroza</ListServices>
<ListServices> Kaszel kennelowy</ListServices>

<TitleServices>Szczepienia podstawowe kotów</TitleServices>
<ListServices> Panleukopenia - zakaźne zapalenie jelit kotów</ListServices>
<ListServices> Białaczka kotów</ListServices>
<ListServices>Wirusowe zakażenia dróg oddechowych - herpeswiroza i kalciwiroza</ListServices>

<TitleServices>Szczepienia królików</TitleServices>
<ListServices> Wirusowa krwotoczna choroba królików - pomór królików</ListServices>
<ListServices> Szczepienie przeciwko myksomatozie</ListServices>

<TitleServices>Szczepienia tchórzofretek</TitleServices>
<ListServices> Nosówka</ListServices>
<ListServices> Leptospiroza</ListServices>

<TitleServices>Odrobaczenia psów i kotów <br />
Ochrona przed ektopasożytami - pchłami i kleszczami</TitleServices>




    </BoxTextPages>

 
    
</Flex>
</FlexContent>
<Footer />
</ThemeProvider>
</>
  )
}

export default profilaktyka